import {mapActions, mapMutations, mapGetters} from 'vuex';

import {required, maxLength, minLength, numeric, integer, minValue, maxValue} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'leftoverTypes.edit',
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        value: null,
        translations: [],
      },
      currentTranslations: 0,
      validationErrors: {},
    }
  },
  components: {},
  validations: {
    payload: {
      value: {
        required,
        numeric,
        integer,
        minValue: minValue(0),
        maxValue: maxValue(1000)
      },
      translations: {
        $each: {
          title: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(20)
          }
        }
      }
    }
  },
  created() {

    let leftoverTypeId = parseInt(this.$route.params.leftoverTypeId);
    if (!this.leftoverType || this.leftoverType.id !== leftoverTypeId) {
      this.fetchLeftoverType(leftoverTypeId).then(()=>{
        this._applyData();
      })
    } else {
      this._applyData();
    }
  },
  mounted() {},
  computed: {
    ...mapGetters({
      leftoverType: 'leftoverTypes/leftoverType',
      isLeftoverTypeLoading: 'leftoverTypes/isLeftoverTypeLoading',
      isLeftoverTypeUpdating: 'leftoverTypes/isLeftoverTypeUpdating',
    }),
    ...mapGetters([
      'languages',
    ]),
    valueErrors() {
      let error = [];
      if (!this.$v.payload.value.$dirty) {
        return error;
      }
      if (!this.$v.payload.value.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.value.numeric) {
        error.push(this.$t('validationNumeric'));
      }
      if (!this.$v.payload.value.integer) {
        error.push(this.$t('validationInteger'));
      }
      if (!this.$v.payload.value.minValue) {
        error.push(this.$t('validationMinValue',{count: '0'}));
      }
      if (!this.$v.payload.value.maxValue) {
        error.push(this.$t('validationMaxValue',{count: 1000}));
      }
      if (this.validationErrors.value) {
        this.validationErrors.value.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      fetchLeftoverType: 'leftoverTypes/LEFTOVER_TYPES_REQUEST_DATA_GET',
      editLeftoverType: 'leftoverTypes/LEFTOVER_TYPES_REQUEST_UPDATE',
    }),
    ...mapMutations({}),
    _applyData() {
      this.payload.value = this.leftoverType.value;
      this.payload.translations = this.languages.map(langItem => {
        let lang = this.leftoverType.translations.data.filter(translationItem => {
          return translationItem.locale === langItem.translationKey;
        })[0];

        let translationItem = {
          locale: langItem.translationKey,
          title: ''
        }

        if (lang) {
          translationItem = Object.assign(translationItem, {
            title: lang.title,
            description: lang.description,
          })
        }

        return translationItem
      });
    },
    translationsTitleErrors(index) {
      let error = [];
      if (!this.$v.payload.translations.$each[index].title.$dirty) {
        return error;
      }
      if (!this.$v.payload.translations.$each[index].title.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.translations.$each[index].title.minLength) {
        error.push(this.$t('validationMin',{count: 1}));
      }
      if (!this.$v.payload.translations.$each[index].title.maxLength) {
        error.push(this.$t('validationMax',{count: 20}));
      }
      return error;
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {

        let payload = {
          value: this.payload.value,
        }
        this.payload.translations.forEach(item => {
          payload[item.locale] = {
            title: item.title,
            description: item.description,
          }
        });
        this.editLeftoverType({
          leftoverTypeId: this.leftoverType.id,
            payload: payload
          }).then(() => {
            this.$toasted.success(this.$t('dataUpdated'));
            this.$router.push({name: 'leftoverTypes.list'}).catch(() => {console.log();});
        }).catch(error => {
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
            this.validationErrors = error.response.data.errors
          }
        });
      }
    }
  }
}
